import React from 'react';
import { useEffect, useState } from 'react';

function Countdown() {
  const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
      countDownDate - new Date().getTime()
    );

    useEffect(() => {
      const interval = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime());
      }, 1000);

      return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
  };

  const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  };

  const x = (time, includeZeroes = true) => {
    if (time < 10 && includeZeroes) {
      return `0${time}:`;
    } else {
      return `${time}:`;
    }
  }

  const christmas = new Date(2022, 11, 25);

  const [days, hours, minutes, seconds] = useCountdown(christmas);

  const secondz = seconds < 10 ? `0${seconds}` : seconds;
  const string = `${x(days, false)}${x(hours)}${x(minutes)}${secondz}`;

  const now = new Date();
  if (now.getDate() >= 25) {
    window.location.reload();
  }

  return (
    <div className="countdown">
      <h1>🎄 Merry Christmas Cece 🎄</h1>
      <h2>Coming Soon!</h2>
      <h2>{string}</h2>
    </div>
  );
}

export default Countdown;
