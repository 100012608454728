import React from 'react';

const letter = 'Merry Christmas Cece! I decided to give you this gift via the internet since that is my area of "expertise." I hope you are having an amazing day filled with love and family and good food and great times. I just wanted to write you to let you know that you are such an amazing, caring, and overall wonderful person. I am very thankful to have you in my life, and although we couldn\'t be together today I figured I would still make sure you got some gift. So, enjoy your gift on the next and final page. You\'re the best and I will see you very soon!'

const ANSWERS = {
  1: {
    question: 'Who is the #1 College Basketball Team in the Country?',
    answers: ['cats', 'wildcats',' wild cats', 'northwestern'],
    error: 'I hope you didn\'t guess Purdue!'
  },
  2: {
    question: 'When is our anniversary?',
    answers: ['9/11', '9/11/2021', 'september 11', 'september 11, 2021', '09/11', '09/11/2021', '9/11/21', '9-11', '9-11-2021'],
    error: 'Try using MM/DD/YYYY'
  },
  3: {
    question: 'What large object scared us (mostly me) at the top of a hill in Sedona?',
    answers: ['plane', 'airplane'],
    error: 'I tried to take a video'
  },
  4: {
    question: 'What does the best piece of trash you\'ve ever found have written on it?',
    answers: ['gamers gonna game'],
    error: 'Certain people are going to do a certain thing'
  },
  5: {
    question: 'What animal (nearly) brought you to tears on our date?',
    answers: ['giraffe'],
    error: 'Lincoln Park Zoo >>>>'
  },
  6: {
    question: 'Who is the cutest, smartest, funniest, goofiest girl out there?',
    answers: ['cece', 'cecelia'],
    error: ':)'
  }
}

class DayOf extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      input: '',
      error: ''
    };
  }

  clickButton() {
    const correctAnswer = ANSWERS[this.state.page];

    if (correctAnswer && !correctAnswer.answers.includes(this.state.input.toLowerCase())) {
      this.setState({ error: correctAnswer.error });
    } else {
      this.setState(state => ({ error: '', input: '', page: state.page + 1 }));
    }
  }

  b(content) {
    return (
      <button
        className="advance-button"
        onClick={ () => this.clickButton() }
      >
        {content}
      </button>
    )
  }

  i() {
    const onKeydown = (event) => {
      if (event.keyCode === 13) {
        this.clickButton();
      }
    }

    return (
      <input
        className="input"
        value={ this.state.input }
        onChange={ (event) => this.setState({ input: event.target.value }) }
        onKeyDown={ onKeydown }
      />
    );
  }

  e() {
    if (!this.state.error) {
      return null;
    }

    return (
      <h2 className="error">{this.state.error}</h2>
    )
  }

  render0() {
    return (
      <>
        <h1>Merry Christmas Cece!</h1>
        <h2>Please verify your identity</h2>
        {this.b("Begin Verification")}
      </>
    )
  }

  q() {
    return (
      <>
        <h1>{ ANSWERS[this.state.page].question }</h1>
        {this.e()}
        {this.i()}
        {this.b("Submit")}
      </>
    )
  }

  final() {
    return (
      <>
        <h1>Verified Cece ✅</h1>
        <h2>🎄 Merry Christmas! 🎄</h2>
        <span>{letter}</span>
        {this.b("View Virtual Gift")}
      </>
    )
  }

  gift() {
    return (
      <>
        <h1>Merry Christmas!</h1>
        <h2>Actual Gifts Coming Soon™️</h2>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/HNPrmS0rtWY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </>
    )
  }

  render() {
    let content;
    if (this.state.page === 0) {
       content = this.render0();
    } else if (ANSWERS[this.state.page]) {
      content = this.q();
    } else if (this.state.page === 7){
      content = this.final();
    } else {
      content = this.gift();
    }

    return (
      <div className="dayof">
        {content}
      </div>
    );
  }
}

export default DayOf;
