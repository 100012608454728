import React from 'react';
import './App.css';
import Countdown from './Countdown';
import DayOf from './DayOf';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hacked: false
    }
  }

  componentDidMount() {
    window.hack = () => this.setState({ hacked: true })
  }

  render() {
    let content;

    // const today = new Date();
    if (/*today.getDate() < 25 && */false && !this.state.hacked) {
      content = (
        <Countdown />
      )
    } else {
      content = (
        <DayOf />
      )
    }
    return (
      <div className="App">
        {content}
      </div>
    );
  }
}

export default App;
